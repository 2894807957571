.actionBtn {
  background-color: transparent !important;
  border-color: transparent !important;
}

.cmtTime {
  padding: 0px 2px !important;
  width: 60px;
  height: 11px;
  border: none !important;
  font-family: "Poppins" !important;
  font-size: 13px !important;
}

.cmntPlayStopBtn {
  cursor: pointer;
}
