.textInput {
  width: 400px;
  height: 32px;
}
.textareaAddressInput {
  width: 400px;
}

.comboBoxInput {
  width: 400px;
}
