.panelTitleBadge {
  background: rgba(144, 115, 127, 0.12);
  padding: 0 5px;
  border-radius: 15px;
  font-size: 15px;
  font-weight: 500;
}

.periodFilters > div:first-child button {
  border-radius: 20px 0 0 20px;
}
.periodFilters > div:last-child button {
  border-radius: 0 20px 20px 0;
}
