.k-card {
  &-title {
    font-size: 1rem;
  }
}
.header-colored {
  .k-card-header {
    background-color: var(--color-main);
    color: $white;
    .k-card-subtitle {
      color: rgba($white, 0.8);
      margin-top: 0;
    }
    .k-button {
      &.k-flat {
        color: $white;
      }
    }
  }
}
