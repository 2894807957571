.tbsIcn {
  display: flex;
  align-items: center;
  margin: 0;
  float: right;
  .toolboxBtn-a {
    margin: 0 0 0 16px;
    font-size: 20px;
    color: var(--color-main);
    cursor: pointer;
  }
}
.collapseForm {
  width: 100%;
  float: left;
  margin: 5px 0 25px 0;
  min-height: 200px;
}

.btn-trk a {
  font-size: 18px;
  margin: 0 5px;
}
.bg-ltgr {
  background: rgba(0, 0, 0, 0.03);
}
.dataTables_wrapper label {
  text-transform: none;
}
.rowTrk {
  float: left;
  width: 100%;
  padding: 5px 15px 5px 15px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  margin: 0 0 25px 0;
  border-radius: 6px;
}
.rowTrkH .rwSpn {
  float: left;
}
.swt-hdn .toggle.btn {
  width: 100px !important;
}
.main-actions {
  float: right;
  width: 100%;
  display: block;
  margin: -15px 0 0 0;
}
.act-trk {
  padding: 0 0 15px 10px;
  float: right;
}
.swt-hdn label {
  text-transform: none;
}
.fileUps .form-control {
  padding: 5px;
  font-size: 13px;
  height: 38px;
}
.text-decoration-underline {
  text-decoration: underline !important;
}
.range-color-graph {
  float: left;
  height: 6px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
.range-color-graph-col {
  width: 100%;
  background: #ccc;
  float: left;
  cursor: pointer;
  flex: auto;
}
.btnTd .btn-link {
  padding: 0;
  margin: 0 7px 0 7px;
}
.rotate-icon {
  position: absolute;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.md-accordion a:not(.collapsed) .rotate-icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.cardEffectAccodion {
  border: 1px solid rgba(0, 0, 0, 0.06) !important;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.04);
  margin: 0 0 15px 0;
  border-radius: 5px !important;
}
.acrd-custom .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.acrd-custom .card-header h5 {
  font-size: 16px;
}
.acn-i {
  float: right;
}
.acn-i a {
  margin: 0 0 0 10px;
  padding: 0 1px 0 1px;
}
.cursor-move {
  cursor: move;
}
.linkNav .toolboxBtn-a:first-child {
  border: none;
}
.linkNav .toolboxBtn-a {
  float: left;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  border-top: 1px solid #ddd;
  padding: 7px 0 7px 0;
}
.optionListTable .chosen-container-multi .chosen-choices .search-choice {
  background: var(--color-main);
  color: #fff;
  border: none;
}
.optionListTable .chosen-container-multi .chosen-choices {
  border: none;
  background: transparent;
}
.optionListTable .chosen-container-multi .chosen-choices .search-field {
  border: 1px dashed #ddd;
  border-radius: 4px;
  height: 24px;
  margin: 3px 0 0 4px;
  display: flex;
}
.optionListTable
  .chosen-container-multi
  .chosen-choices
  .search-field
  .chosen-search-input {
  min-height: 24px;
}
.conditionCOl {
  float: left;
}
.conditionCOl-p {
  float: left;
  width: 100%;
}
.conditionCOl-h {
  float: left;
  width: 100%;
  font-weight: 600;
  color: var(--color-main);
}
.optionListForm .chosen-container-multi .chosen-choices .search-choice {
  background: var(--color-main);
  color: #fff;
  border: none;
}
.multibox-primary .chosen-container-multi .chosen-choices .search-choice {
  background: var(--color-main);
  color: #fff;
}
.sectionDiv .srt1-header {
  float: left;
  width: 100%;
}
.sectionDiv .srt1-header a {
  float: left;
}
.profile-dtl .user-img-name img,
.profile-dtl .user-img-name span {
  height: 50px;
  width: 52px;
  border-radius: 50%;
  margin-right: 15px;
  font-size: 24px;
  font-weight: 300;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
}
.keywordRow {
  float: left;
}
.keywordTrk {
  background: rgba(0, 0, 0, 0.08);
  margin: 0 5px 6px 0;
  border-radius: 4px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  float: left;
  color: rgba(0, 0, 0, 0.66);
  min-width: 26px !important;
  width: auto !important;
  justify-content: center !important;
}
.fade-swt .switch {
  opacity: 0.3;
}
.fade-swt .switch:hover {
  opacity: 1;
}
.op-disable {
  opacity: 0.4;
}
.op-enable {
  opacity: 1;
}
