.my-swal{
    z-index: 10003 !important;
}

.unselected-color{
    color:#ccc;
}

.unselected-bgcolor{
    background-color:#ccc;
}